<template>
  <MapboxMap :map-style="style" :access-token="token" :center="mapCenter" :zoom="16" :maxZoom="18" :minZoom="12"
    @mb-load="doTrigger">
    <MapboxImage id="paradero" src="/paradero.png">
      <MapboxCluster :data="layerOptions" unclustered-point-layer-type="symbol" :unclustered-point-paint="null"
        :clusterMaxZoom="11" :unclustered-point-layout="{
            'icon-image': 'paradero',
            'icon-size': .2,
          }" />
    </MapboxImage>
    <MapboxGeolocateControl ref="geolocate" v-bind:showAccuracyCircle="true" v-bind:trackUserLocation="true"
      :positionOptions="{ enableHighAccuracy: true }" v-bind:showUserHeading="false" />
    <MapboxNavigationControl position="bottom-right" ref="nav" />
  </MapboxMap>
</template>

<script>

import { MapboxMap, MapboxCluster, MapboxImage, MapboxNavigationControl, MapboxGeolocateControl } from '@studiometa/vue-mapbox-gl';
//import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { initializeApp } from 'firebase/app'
import { getDatabase, ref as fireRef, get, onValue, query, orderByChild, equalTo } from 'firebase/database'

const firebaseConfig = {
  apiKey: "AIzaSyAJ24SxwE5IV70qQXOYo-Ia7FMqnxP9i2M",
  authDomain: "tumicro-54402.firebaseapp.com",
  databaseURL: "https://tumicro-54402-default-rtdb.firebaseio.com",
  projectId: "tumicro-54402",
  storageBucket: "tumicro-54402.appspot.com",
  messagingSenderId: "806215345998",
  appId: "1:806215345998:web:843f5e4f74b36a4966a01f",
  measurementId: "G-JTDK6WHSW7"
};

const app = initializeApp(firebaseConfig)
const db = getDatabase(app)
const Paraderos = fireRef(db, '/Paraderos')
const Micros = query(fireRef(db, '/micros'), orderByChild('visible'), equalTo(true))

let layerOptions = {
  type: 'FeatureCollection',
  features: null
};

let layerMicro = {
  type: 'FeatureCollection',
  features: []
};

export default {
  name: 'MapComponent',

  components: {
    MapboxMap,
    MapboxCluster,
    MapboxImage,
    MapboxNavigationControl,
    MapboxGeolocateControl
  },

  setup: async () => {


    await get(Paraderos).then((snapshot) => {
      layerOptions.features = snapshot.val().map(x => ({
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [x.PuntoGPS.Longitud, x.PuntoGPS.Latitud],
        },
      }));
    });
  },

  data: () => {

    return {
      token: "pk.eyJ1IjoiZmVpdmFsZW4iLCJhIjoiY2xnMzJoNnpyMDhjeDNjcGdrd2Q1MTU5eCJ9.3h_F_2nt21cq6eWTbFuyOw",
      style: "mapbox://styles/mapbox/streets-v12",
      mapCenter: [-73.0497700, -36.8269900],
      layerOptions,
      layerMicro,
    }
  },

  methods: {
    doTrigger(ev) {
      let map = ev.target;
      let image = new Image();
      image.src = '/micro.png';

      onValue(Micros, (snapshot) => {

        if (snapshot.hasChildren()) {
          layerMicro.features = Object.entries(snapshot.val()).map(x => ({
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: [x[1].longitud, x[1].latitud],
            },
          }));
        }
        else
          layerMicro.features = [];

        let source = map.getSource('microsSource');

        if (source == null) {
          map.addSource('microsSource', {
            type: 'geojson',
            data: layerMicro
          });

          map.addImage('micro', image);

          map.addLayer({
            'id': 'points',
            'type': 'symbol',
            'source': 'microsSource', // reference the data source
            'layout': {
              'icon-image': 'micro', // reference the image
              'icon-size': 0.2
            }
          });

        }
        else
          source.setData(layerMicro);

      });

      /*
      let control = new mapboxgl.GeolocateControl({
        positionOptions: {
          enableHighAccuracy: true
        },
        trackUserLocation: true,
        showUserHeading: false
      });

      map.addControl(control);


      */
    }
  }
}
</script>

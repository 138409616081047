<template>
  <v-app>
    <Suspense>
      <MapComponent />

      <template #fallback>
        <v-container class="fill-height loader">
          <BeatLoader color="#4263EC"></BeatLoader>
        </v-container>
      </template>
    </Suspense>
  </v-app>
</template>

<script>

import MapComponent from './components/MapComponent.vue'
import BeatLoader from 'vue-spinner/src/BeatLoader.vue'

export default {
  name: 'App',

  components: {
    MapComponent,
    BeatLoader
  },

}
</script>